import { useMsal } from "@azure/msal-react";
import { loginRequest } from "auth/authConfig";
import { useStore } from "react-redux";
import { setAccessToken } from "store/session";
import store from "store/store";
import config from "../config";

export async function GetToken(instance) {
    const state = store.getState();

        await instance.acquireTokenSilent({...loginRequest, account: state.sessionState.user}).then((response) => {
            store.dispatch(setAccessToken(response.accessToken));
            return response.accessToken;
        }).catch((e) => {
            instance.acquireTokenPopup({...loginRequest, account: state.sessionState.user}).then((response) => {
                store.dispatch(setAccessToken(response.accessToken));
                return response.accessToken;
            });
        });
    }
        
 
export async function PDMLogin(instance) {
    await GetToken(instance);
    const state = store.getState();  
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;      
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    headers.append("Authorization", bearer);  
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;
    
    await fetch(config.restUrl + "PDM/Login", {
        method: "GET",
        headers: headers
    }).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });     
    return output;
}
 
export async function fetchId(instance,ad_name) {
    await GetToken(instance);
    const state = store.getState();  
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;      
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    headers.append("Authorization", bearer);  
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);
    headers.append("ad_name", ad_name);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;
    
    await fetch(config.restUrl + "PDM/FetchId", {
        method: "GET",
        headers: headers
    }).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });     
    return output;
}

export async function fetchValidationId(instance) {
    await GetToken(instance);
    const state = store.getState();  
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;      
    headers.append("Authorization", bearer);  
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;
    
    await fetch(config.restUrl + "Validation/FetchRollId", {
        method: "GET",
        headers: headers
    }).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });     
    return output;
}

export async function fetchCompareId(instance) {
    await GetToken(instance);
    const state = store.getState();  
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;      
    headers.append("Authorization", bearer);  
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;
    
    await fetch(config.restUrl + "Compare/FetchRollId", {
        method: "GET",
        headers: headers
    }).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });     
    return output;
}

export async function fetchMaterialCharacteristics(instance,ad_name,material,revision) {
    await GetToken(instance);
    const state = store.getState();  
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;      
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    headers.append("Authorization", bearer);   
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);
    headers.append("ad_name", ad_name);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;   
    await fetch(config.restUrl + "PDM/FetchMaterialCharacteristics/" + material + "/" + revision, {
        method: "GET",
        headers: headers
    }).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });   
    return output;
}

export async function downloadTrace(instance, order_name, ad_name) {
    await GetToken(instance);
    const state = store.getState();
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);       
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);   
    headers.append("ad_name", ad_name);  
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");     
    let output = null;
    let fetchUrl = config.restUrl + "PDM/GetConfigTrace?ordername=" + order_name;

    await fetch(fetchUrl, 
    {
        method: "POST",
        headers: headers
    }      
    ).then(res => res.json()).then((result) => {
    
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        const blob = new Blob([result], {type: "text/plain"});
        const href = window.URL.createObjectURL(blob);
        a.href = href;
        a.download = "trace.txt";
        a.click();
        window.URL.revokeObjectURL(href);
        
        console.log("Download completed.");  
        
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function materialRevisionItemsExists(instance, materialRevisionCondition, ad_name) {
    await GetToken(instance);
    const state = store.getState();  
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;      
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    headers.append("Authorization", bearer);   
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);
    headers.append("ad_name", ad_name);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;   
    await fetch(config.restUrl + "PDM/MaterialRevisionItemsExist", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "materialsRevisionsCondition": materialRevisionCondition,
        })
    }).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });   
    return output;
}

export async function getConsoleMaterials(instance,_userName) {
    await GetToken(instance);
    const state = store.getState();  
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token; 
    headers.append("Authorization", bearer); 
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");   
    let output = null;
    await fetch(config.restUrl + "CWSConsole/GetMaterials/" + _userName, {
        method: 'GET',
        headers: headers
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    });
    return output;
}

export async function getBatches(instance) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);    
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff"); 
    let output = null;
    await fetch(config.restUrl + "Batch", 
    {
        method: "GET",
        headers: headers
    }
    ).then(res => {
        console.log("RES: ", res);
        return res.json();
    }
    ).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;  
}

export async function getBatchesFiltered(instance, namefilter) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer); 
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");    
    let output = null;
    await fetch(config.restUrl + "getBatches/" + namefilter, 
    {
        method: "GET",
        headers: headers
    }
    ).then(res => {
        console.log("RES: ", res);
        return res.json();
    }
    ).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;  
}

export async function getBatch(instance, _batchId) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer); 
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");    
    let output = null;
    await fetch(config.restUrl + "Batch/" + _batchId, 
    {
        method: "GET",
        headers: headers
    }
    ).then(res => {
        return res.json();
    }
    ).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;  
}

export async function getMaterial(instance, id) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);   
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");  
    let output = null;
    await fetch(config.restUrl + "Material/" + id, 
    {
        method: "GET",
        headers: headers
            
            //"Access-Control-Allow-Origin": "*",
            //"Content-Type": "text/plain"

    }
    ).then(res => {
        //console.log("RES: ", res);
        return res.json();
    }
    ).then((result) => {
        output = result;
    });
    return output;    
}

export async function getBatchOrders(instance, id) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;
    await fetch(config.restUrl + "Batch/" + id + "/Orders", 
    {
        method: "GET",
        headers: headers
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    });
    return output;
}

export async function getBatchesErrorModeLists(instance) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;
    await fetch(config.restUrl + "Batch/ErrorModeLists", 
    {
        method: "GET",
        headers: headers
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    });
    return output;
}
    
export async function createBatch(instance, _name, _description, _material, _materialRevision, _orders, _defaultCharacteristics, _executeArguments, _executeOrders, _pdmdescription) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;                     
    console.log(_name);                    
    console.log(_description);                    
    console.log(_material);                    
    console.log(_materialRevision);         
    console.log(_orders);         
    console.log(_defaultCharacteristics);
    console.log(_pdmdescription);
    await fetch(config.restUrl + "Batch/Create", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "name": _name,
            "description": _description,
            "material": _material,
            "materialRevision": _materialRevision,
            "orders": _orders,
            "defaultCharacteristics": _defaultCharacteristics,
            "executeOrders": _executeOrders,
            "executeArguments": _executeArguments,
            "pdmdescription": _pdmdescription,
        })
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function updateBatch(instance, _id, _name, _description, _orders, _defaultCharacteristics, _executeArguments, _executeOrders, _pdmdescription) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;
        
    await fetch(config.restUrl + "Batch/" + _id + "/Update", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "name": _name,
            "description": _description,
            "orders": _orders,
            "defaultCharacteristics": _defaultCharacteristics,
            "executeOrders": _executeOrders,
            "executeArguments": _executeArguments,
            "pdmdescription": _pdmdescription,
        })
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function executeBatch(instance, _batchID, _orders, _executeArguments, batchOrders, ad_name) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');     
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);   
    headers.append("ad_name", ad_name);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;             
    await fetch(config.restUrl + "Batch/" + _batchID + "/Execute", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "orderArray": _orders,
            "executeArguments": _executeArguments,
            "orders": batchOrders
        })
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function deleteBatch(instance, _batchId, ad_name) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);     
    headers.append("ad_name", ad_name);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;
    await fetch(config.restUrl + "Batch/" + _batchId, 
    {
        method: "DELETE",
        headers: headers
    }
    ).then(res => {
        return res.json();
    }
    ).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;  
}

export async function RefreshStatus(instance, _batchID, ad_name) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    headers.append("Authorization", bearer);
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);   
    headers.append("ad_name", ad_name);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;             
    await fetch(config.restUrl + "Batch/" + _batchID + "/RefreshStatus", {
        method: 'GET',
        headers: headers
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function CancelBatch(instance, _batchID, _orders) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;
    await fetch(config.restUrl + "Batch/" + _batchID + "/Cancel", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "orders": _orders
        })
    }
    ).then(res => res).then((result) => {
        //console.log("RESULT: ", result);
        output = result;   
    }).catch(error => {
        console.log(error);
    });
    return output;
}
export async function CancelBatchConsole(instance, _ad_name, _batch_name, _last_run) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    headers.append("Authorization", bearer);  
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword); 
    headers.append("ad_name", _ad_name);
    headers.append("batch_name", _batch_name);
    headers.append("last_run", _last_run);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
        console.log(_ad_name);
        console.log(_batch_name);
        console.log(_last_run);
    let output = null;
    await fetch(config.restUrl + "CWSConsole/CancelBatch", {
        method: 'GET',
        headers: headers
    }
    ).then(res => res).then((result) => {
        //console.log("RESULT: ", result);
        output = result;   
    }).catch(error => {
        console.log(error);
    });
        console.log(output);
    return output;
}

export async function shareBatch(instance, _batchID, _shareTo) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Batch/" + _batchID + "/Share", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "shareTo": _shareTo
        })
    }
    ).then(res => res).then((result) => {
        //console.log("RESULT: ", result);
        output = result;
        return output;
    });
}

export async function unShareBatch(instance, _batchID) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Batch/" + _batchID + "/Unshare", {
        method: "GET",
        headers: headers
    }
    ).then(res => res).then((result) => {
        output = result;
    });
    return output;
}

// export async function unShareBatch(instance, _batchID, _removeFrom) {
//     await GetToken(instance);
//     const state = store.getState();
//     let headers = new Headers();
//     let bearer = "Bearer " + state.sessionState.token;
//     headers.append("Authorization", bearer);
//     headers.append('Accept', 'application/json');
//     headers.append('Content-Type', 'application/json');
//     let output = null;
//     await fetch(config.restUrl + "Batch/" + _batchID + "/Unshare", {
//         method: 'POST',
//         headers: headers,
//         body: JSON.stringify({
//             // parameters
//             "sharedBy": config.userName,
//             "removeFrom": _removeFrom
//         })
//     }
//     ).then(res => res).then((result) => {
//         output = result;
//     });
//     return output;
// }

export async function createOrder(instance, _batchID, _name, _description, _characteristics, _userName) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Order/Create", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "batchID": _batchID,
            "name": _name,
            "description": _description,
            "characteristics": _characteristics,
            "userName": _userName
        })
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    });
    return output;
}

export async function updateOrder(instance, _orderID, _batchID, _name, _description, _status, _message, _characteristics) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Order/" + _orderID, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "batchID": _batchID,
            "name": _name,
            "description": _description,
            "characteristics": _characteristics,
            "status": _status,
            "message": _message
        })
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    });
    return output;
}

export async function getSharedWith(instance, _userName = store.getState().sessionState.user.username) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Share/SharedWith/" + _userName, 
    {
        method: "GET",
        headers: headers
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    });
    return output;
}

export async function getSharedBy(instance, _userName = store.getState().sessionState.user.username) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Share/SharedBy/" + _userName, 
    {
        method: "GET",
        headers: headers
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    });
    return output;
}

export async function getSharedWithMe(instance) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Batch/SharedWithMe", 
    {
        method: "GET",
        headers: headers
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function getValidations(instance) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    let fetchUrl = config.restUrl + "Validation";

    await fetch(fetchUrl, 
    {
        method: "GET",
        headers: headers
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    });
    return output;
}

export async function getValidation(instance, _validationid) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);     
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Validation/" + _validationid, 
    {
        method: "GET",
        headers: headers
    }
    ).then(res => {
        return res.json();
    }
    ).then((result) => {
        output = result;
    });
    return output;  
}

export async function createValidation(instance, _validationType, _name, _description, _materials, _options, _pdmdescription, _dvalert_status) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Validation/Create", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "validationType": _validationType,
            "name": _name,
            "description": _description,
            "materials": _materials,
            "options": _options,
            "pdmdescription": _pdmdescription,
            "dvalertstatus":_dvalert_status,
        })
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function updateValidation(instance, _validationid, _name, _description, _materials, _options, _pdmdescription, _dvalert_status) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Validation/" + _validationid + "/Update", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "name": _name,
            "description": _description,
            "materials": _materials,
            "options": _options,
            "pdmdescription": _pdmdescription,
            "dvalertstatus":_dvalert_status,
        })
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    });
    return output;
}

export async function executeValidation(instance, _validationid, _materials, _options, ad_name) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token; 
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    headers.append("Authorization", bearer);  
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);
    headers.append("ad_name", ad_name);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Validation/" + _validationid + "/Execute", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "materials": _materials,
            "options": _options
        })
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function deleteValidation(instance, _validationId, ad_name) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);     
    headers.append("ad_name", ad_name);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Validation/" + _validationId, 
    {
        method: "DELETE",
        headers: headers
    }
    ).then(res => {
        return res.json();
    }
    ).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;  
}

export async function downloadFromS3Base64(instance, _route, _name, ad_name) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append("ad_name", ad_name);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    let fetchUrl = config.restUrl + _route + "/DownloadFromS3Base64/" + _name;
    console.log(fetchUrl);
    await fetch(fetchUrl, 
    {
        method: "GET",
        headers: headers
    }      
    ).then(res => res.json()).then((result) => {
        const byteCharacters = atob(result);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

        const href = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = href;
        a.download = _name + ".xlsx";
        a.click();
        window.URL.revokeObjectURL(href);
        console.log("Download completed.");  
        
    }).catch(error => {
        console.log(error);
    });
    
    return output;
}

/*export async function getCompares(instance, _userid, _compareType = "") {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    let output = null;
    let fetchUrl = config.restUrl + "Compare/" + _userid;
    if (_compareType != "")
    {
        fetchUrl = fetchUrl + "?compareType=" + _compareType
    }
    await fetch(fetchUrl, 
    {
        method: "GET",
        headers: headers
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;
}*/

export async function getCompares(instance) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    let fetchUrl = config.restUrl + "Compare";

    await fetch(fetchUrl, 
    {
        method: "GET",
        headers: headers
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function getCompare(instance, _compareID) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);   
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
  
    let output = null;
    await fetch(config.restUrl + "Compare/" + _compareID, 
    {
        method: "GET",
        headers: headers
    }
    ).then(res => {
        return res.json();
    }
    ).then((result) => {
        output = result;
    });
    return output;  
}

export async function createCompare(instance, _compareType, _name, _description, _configurations, _compareto, _options) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Compare/Create", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "compareType": _compareType,
            "name": _name,
            "description": _description,
            "configurations": _configurations,
            "compareto": _compareto,
            "options": _options
        })
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    });
    return output;
}

export async function updateCompare(instance, _compareid, _name, _description, _configurations, _compareto, _options) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Compare/" + _compareid + "/Update", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "name": _name,
            "description": _description,
            "configurations": _configurations,
            "compareto": _compareto,
            "options": _options
        })
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    });
    return output;
}

export async function executeCompare(instance, _compareid, _configurations, _compareto, _options, ad_name) {
    await GetToken(instance);
    const state = store.getState();
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');   
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);   
    headers.append("ad_name", ad_name);    
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Compare/" + _compareid + "/Execute", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "configurations": _configurations,
            "compareto": _compareto,
            "options": _options
        })
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    });
    return output;
}

export async function deleteCompare(instance, _compareId, ad_name) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);     
    headers.append("ad_name", ad_name);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Compare/" + _compareId, 
    {
        method: "DELETE",
        headers: headers
    }
    ).then(res => {
        return res.json();
    }
    ).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;  
}

export async function OptionsPackageDownload(instance, _koneMaterial, _koneMaterialRevision, ad_name) {
    await GetToken(instance);
    const state = store.getState();
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);       
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);   
    headers.append("ad_name", ad_name);   
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
 
    let output = null;
    let fetchUrl = config.restUrl + "CWSConsole/DownloadOptionsPackage?&material=" + _koneMaterial + "&revision=" + _koneMaterialRevision;

    await fetch(fetchUrl, 
    {
        method: "GET",
        headers: headers
    }    
    ).then(res => res.json()).then((result) => {
    
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        
        const byteCharacters = atob(result);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], {type: "application/octet-stream"});
        const href = window.URL.createObjectURL(blob);
        a.href = href;
        a.download = _koneMaterial + "_" + _koneMaterialRevision + ".zip";
        a.click();
        window.URL.revokeObjectURL(href);
        console.log("Download completed.");  
        
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function OptionsPackageUpload(instance, _koneMaterial, _koneMaterialRevision, _zipFile, _fileBinaryStr, ad_name) {
    await GetToken(instance);
    const state = store.getState();
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    let headers = new Headers();
    let fData = new FormData();
    fData.append("file", _fileBinaryStr);
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);           
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword); 
    headers.append("ad_name", ad_name);   
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
 
    let output = null;
    let fetchUrl = config.restUrl + "CWSConsole/UploadOptionsPackage?&material=" + _koneMaterial + "&revision=" + _koneMaterialRevision + "&zipName=" + _zipFile;

    await fetch(fetchUrl, 
    {
        method: "POST",
        headers: headers,
        body: fData
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function DownloadConsoleResults(instance, order_name, configuration_id, result_type, ad_name) {
    await GetToken(instance);
    const state = store.getState();
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);       
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);   
    headers.append("ad_name", ad_name);    
    headers.append("configuration_id", configuration_id);    
    headers.append("result_type", result_type);   
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
 
    let output = null;
    let fetchUrl = config.restUrl + "CWSConsole/DownloadResults";

    await fetch(fetchUrl, 
    {
        method: "GET",
        headers: headers
    }      
    ).then(res => res.json()).then((result) => {
    
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        
        const byteCharacters = atob(result);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers)
        if (result_type == "XML")
        {
          const blob = new Blob([byteArray], {type: "text/xml"});
          const href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = configuration_id.replace('/', '_') + ".xml";
          a.click();
          window.URL.revokeObjectURL(href);
        }
        else
        {
          const blob = new Blob([byteArray], {type: "application/octet-stream"});
          const href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = order_name + "_" + result_type + ".zip";
          a.click();
          window.URL.revokeObjectURL(href);
        }
        console.log("Download completed.");  
        
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function DownloadConfigurationXml(instance, configuration_id, ad_name) {
    await GetToken(instance);
    const state = store.getState();
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);       
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);   
    headers.append("ad_name", ad_name);       
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    let fetchUrl = config.restUrl + "PDM/DownloadConfigurationXml?configuration_id=" + configuration_id;

    await fetch(fetchUrl, 
    {
        method: "GET",
        headers: headers
    }      
    ).then(res => res.json()).then((result) => {
    
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        
        const byteCharacters = atob(result);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers)
        
          const blob = new Blob([byteArray], {type: "text/xml"});
          const href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = configuration_id.replace('/', '_') + ".xml";
          a.click();
          window.URL.revokeObjectURL(href);
        
        console.log("Download completed.");  
        
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function fetchPDMDescription(instance,ad_name,material,revision) {
    await GetToken(instance);
    const state = store.getState();  
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;      
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    headers.append("Authorization", bearer);   
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);
    headers.append("ad_name", ad_name);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;   
    await fetch(config.restUrl + "PDM/FetchPDMDescription/" + material + "/" + revision, {
        method: "GET",
        headers: headers
    }).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });   
    return output;
}

export async function CancelDataValidation(instance, _validationID, _materials, _options, _ad_name, _batch_name, _last_run, _dvalert_status) {
    console.log("----Inside CancelDataValidation() function----");
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("ad_name", _ad_name);
    headers.append("batch_name", _batch_name);
    headers.append("last_run", _last_run);
    headers.append("dvalert_status", _dvalert_status);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    let output = null;
    await fetch(config.restUrl + "Validation/" + _validationID + "/Cancel", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "materials": _materials,
            "options": _options,
            "dvalertstatus": _dvalert_status
        })
    }
    ).then(res => res).then((result) => {
        //console.log("RESULT: ", result);
        output = result;   
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function CancelBomCompare(instance, _compareID, _configurations, _compareto, _options, _ad_name, _compare_name, _last_run) {
    console.log("----Cancel BomCompare----");
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("ad_name", _ad_name);
    headers.append("compare_name", _compare_name);
    headers.append("last_run", _last_run);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    console.log(_compareID);
    console.log(_configurations);
    console.log(_compareto);
    console.log(_options);
    console.log(_ad_name);
    console.log(_compare_name);
    console.log(_last_run);
    let output = null;
    await fetch(config.restUrl + "Compare/" + _compareID + "/Cancel", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "configurations": _configurations,
            "compareto": _compareto,
            "options": _options
        })
    }
    ).then(res => res).then((result) => {
        //console.log("RESULT: ", result);
        output = result;   
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function DownloadAllResults(instance, batchName, configuration_id, result_type, ad_name) {
    await GetToken(instance);
    const state = store.getState();
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);       
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);   
    headers.append("ad_name", ad_name);    
    headers.append("configuration_id", configuration_id); 
    headers.append("batch_name", batchName);   
    headers.append("result_type", result_type);  
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
  
    let output = null;
    let fetchUrl = config.restUrl + "CWSConsole/DownloadAllResults";
    await fetch(fetchUrl, 
    {
        method: "POST",
        headers: headers
    }      
    ).then(res => res.json()).then((result) => {
    
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        
        const byteCharacters = atob(result);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers)
          const blob = new Blob([byteArray], {type: "application/octet-stream"});
          const href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = batchName + ".zip";
          a.click();
          window.URL.revokeObjectURL(href);
          
        console.log("Download completed.");  

        sessionStorage.setItem("successDownload", "true");
        
    }).catch(error => {
        console.log(error);
        sessionStorage.setItem("failedDownload", "true");
    });
    sessionStorage.setItem("circularProgress", "false");

    let successDownload = sessionStorage.getItem("successDownload");
    sessionStorage.removeItem("successDownload");
    
    let failedDownload = sessionStorage.getItem("failedDownload");
    sessionStorage.removeItem("failedDownload");

    var msg="";
    if(successDownload!=null && successDownload=="true")
    {
        msg="File downloaded successfully";
    }
    else if(failedDownload==null)
    {
        msg="File downloading failed";
    }
    else
    {
        msg="File downloading failed (Gateway Timeout)";
    }
    setTimeout(() => {
        alert(msg);
    }, 1500);
    return output;
}

export async function CancellingBomCompare(instance, _compareID, _configurations, _compareto, _options) {
    console.log("----Cancelling BomCompare----");
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    console.log(_compareID);
    console.log(_configurations);
    console.log(_compareto);
    console.log(_options);
    
    let output = null;
    await fetch(config.restUrl+"Compare/"+_compareID+"/Cancelling", {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            // parameters
            "configurations": _configurations,
            "compareto": _compareto,
            "options": _options
        })
    }
    ).then(res => res).then((result) => {
        //console.log("RESULT: ", result);
        output = result;   
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function CancellingCompareConsole(instance, _ad_name, _compare_name, _last_run) {
    console.log("----Acknowledging Cancelling BomCompare----");
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    headers.append("Authorization", bearer);  
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword); 
    headers.append("ad_name", _ad_name);
    headers.append("compare_name", _compare_name);
    headers.append("last_run", _last_run);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");

    console.log(_ad_name);
    console.log(_compare_name);
    console.log(_last_run);
    let output = null;
    await fetch(config.restUrl+"CWSConsole/CancellingCompare", {
        method: 'GET',
        headers: headers
    }
    ).then(res => res).then((result) => {
        //console.log("RESULT: ", result);
        output = result;   
    }).catch(error => {
        console.log(error);
    });
        console.log(output);
    return output;
}

export async function TrackingPDMConnection(instance) {
    console.log("--------TrackingPDMConnection--------");
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;
    await fetch(config.restUrl+"CWSConsole/TrackPDMConnection", {
        method: 'GET',
        headers: headers
    }
    ).then(res => res.json()).then((result) => {
        //console.log("RESULT: ", result);
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function fetchDataFromPDM(instance, orders, items) {
    const requestBody = {
        orders: orders,  
        items: items     
    };
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;
        try {

            const response = await fetch(config.restUrl + "Batch/fetchDatafromPDM", {
                method: "POST",  
                headers: headers,
                body: JSON.stringify(requestBody) 
            });
            if (response.ok) {
                const result = await response.json();
                output = result;
            } else {
                console.error('Failed to fetch data:', response.statusText);
                output = { error: response.statusText };
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            output = { error: error.message };
        }

        return output;
    }


export async function checkMaterialCharacteristics(instance,ad_name,material,revision) {
    await GetToken(instance);
    const state = store.getState();  
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;      
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    headers.append("Authorization", bearer);   
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);
    headers.append("ad_name", ad_name);
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    let output = null;   
    await fetch(config.restUrl + "PDM/CheckFetchPDMDescription/" + material + "/" + revision, {
        method: "GET",
        headers: headers
    }).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });   
    return output;
}

export async function checkCADPackage(instance, _material, _materialRevision) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    let pdmUsername = sessionStorage.getItem("pdmUsername");
    let pdmPassword = sessionStorage.getItem("pdmPassword");
    headers.append("Authorization", bearer);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");
    headers.append("material", _material);
    headers.append("materialRevision", _materialRevision);
    headers.append("username", pdmUsername);
    headers.append("password", pdmPassword);
    let output = null;                     
    console.log(_material);
    console.log(_materialRevision);
    await fetch(config.restUrl + "CWSConsole/CheckCADPackage", {
        method: 'GET',
        headers: headers
    }
    ).then(res => res.json()).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;
}

export async function getAllMaterialNames(instance) {
    await GetToken(instance);
    const state = store.getState();
    let headers = new Headers();
    let bearer = "Bearer " + state.sessionState.token;
    headers.append("Authorization", bearer); 
    headers.append("Cache-Control", "no-store");
    headers.append("Strict-Transport-Security", "max-age=31536000");
    headers.append("Content-Security-Policy", "upgrade-insecure-requests");
    headers.append("X-Frame-Options", "sameorigin");
    headers.append("X-Content-Type-Options", "nosniff");    
    let output = null;
    await fetch(config.restUrl + "Batch/getAllMaterialNames", 
    {
        method: "GET",
        headers: headers
    }
    ).then(res => {
        return res.json();
    }
    ).then((result) => {
        output = result;
    }).catch(error => {
        console.log(error);
    });
    return output;  
}
