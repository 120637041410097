import React, { useCallback, useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LayersIcon from '@material-ui/icons/Layers';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Divider from '@material-ui/core/Divider';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import { ClickAwayListener, Collapse, Icon, makeStyles } from '@material-ui/core';
import { AssignmentTurnedIn, CheckBox, ExpandLess, ExpandMore, PlaylistAddCheck, ShowChart } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  imageIcon: {
    height: '100%'
  },
  iconRoot: {
    textAlign: 'center'
  }
}));

export default function SelectedListItem(props) {
  const classes = useStyles();
  const indexHandler = props.indexHandler;
  const titleHandler = props.titleHandler;
  const pathHandler = props.pathHandler;
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [selectedItem, setSelectedItem] = useState("");
  const [openProduction, setOpenProduction] = React.useState(false);
  const [openCWS, setOpenCWS] = React.useState(false);
  const [validationIn, setValidationIn] = useState(true);
  const history = useHistory();
  const location = useLocation();

  const handleClickCWS = () => {
    setOpenCWS(!openCWS);
  };

  const handleClickAway = () => {
    setSelectedItem("");
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    indexHandler(index, "", "");
    if (index == 0) {
      history.push("/");
    }
    else if (index == 2) {
      history.push("/batches");
    }
    else if (index == 6) {
      history.push("/datavalidation");
    }
    else if (index == 7) {
      history.push("/bomcompare");
    }
    else if (index == 8) {
      history.push("/admintools");
    }
  };

  useEffect(() => {
    setSelectedIndex(0);

    indexHandler(0);
    titleHandler(["Production"]);
    pathHandler("");
  }, [props.homeHandler]);

  useEffect(() => {
    if (location["pathname"] == "/batches" || location["pathname"] == "/batch") {
      setSelectedIndex(2);
    }
    else if (location["pathname"] == "/datavalidation" || location["pathname"] == "/datavalidation/edit") {
      setSelectedIndex(6);
    }
    else if (location["pathname"] == "/bomcompare" || location["pathname"] == "/bomcompare/edit") {
      setSelectedIndex(7);
    }

    if (location["pathname"] == "/bomcompare" || location["pathname"] == "/datavalidation"
    || location["pathname"] == "/bomcompare/edit" || location["pathname"] == "/datavalidation/edit"){
      setValidationIn(false);
      if(selectedItem != "Validation"){
        setSelectedItem("Validation");
      }
    }
    else
    {
      setValidationIn(true);
    }
  }, [location]);

  return (
    <div>
      <List>
      <ListItem
          button
          selected={selectedIndex === 0}
          onClick={(event) => {
            handleListItemClick(event, 0)
          }
          }
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        
        <ListItem
          button
          selected={selectedIndex === 2}
          onClick={(event) => {
            handleListItemClick(event, 2)
          }
          }
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="My batches" />
        </ListItem>

        <ClickAwayListener onClickAway={handleClickAway}>
        
        <ListItem
          button
          selected={selectedIndex === 5}
          onClick={(event) => {
            // handleListItemClick(event, 5);
            // handleClickCWS();
            setValidationIn(!validationIn);
            if (selectedItem == "Validation")
            {
              setSelectedItem("");
            } else 
            {
              setSelectedItem("Validation");
            }
          }
        }
        >
          <ListItemIcon>
            <CheckBox />
          </ListItemIcon>
          <ListItemText primary="Validation" />
          {(validationIn) ? 
          <ListItemIcon><ExpandMore /></ListItemIcon> : 
          <ListItemIcon><ExpandLess /></ListItemIcon>}
        </ListItem>
        </ClickAwayListener>

        <Collapse unmountOnExit in={!validationIn}> 
        <ListItem
          button
          selected={selectedIndex === 6}
          className={classes.nested}
          onClick={(event) => {
            setSelectedItem("DataValidation");
            handleListItemClick(event, 6);
            event.stopPropagation();
          }
        }
        >
          <ListItemIcon>
            <AssignmentTurnedIn />
          </ListItemIcon>
          <ListItemText primary="Data validation" />         
        </ListItem>

        <ListItem
          button
          selected={selectedIndex === 7}
          className={classes.nested}
          onClick={(event) => {
            setSelectedItem("BOMCompare");
            handleListItemClick(event, 7);
            event.stopPropagation();
          }
        }
        >
          <ListItemIcon>
            <PlaylistAddCheck />
          </ListItemIcon>
          <ListItemText primary="BOM compare" />
        </ListItem>
        </Collapse>

        <ListItem
            button
            selected={selectedIndex === 8}
            onClick={(event) => {
                handleListItemClick(event, 8)
            }
            }
        >
            <ListItemIcon>
                <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary="Admin tools" />
        </ListItem>
        

        {/* <ListItem
          button
          selected={selectedIndex === 3}
          onClick={(event) => {
            handleListItemClick(event, 3)
            handleClickCWS()
          }
          }
        >
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Test order templates" />
        </ListItem> */}

        {/* <ListItem
          button
          selected={selectedIndex === 4}
          onClick={(event) => {
            handleListItemClick(event, 4)
          }
          }
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem> */}
      </List>
      <Divider />
    </div>
  );
}